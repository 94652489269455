@import '../../../../shared/styles/variable';
@import '../../../../shared/styles/mixin';

.chat-content-screen {
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #e6e6e6;
  border-radius: 16px;

  @media screen and (max-width: @screen-sm) {
    border-radius: 0;
    border-bottom-width: 0;
    border-top-width: 0;
  }
}

.chat-no-user-screen {
  padding: 16px;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  border: 1px solid #e6e6e6;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;

  @media screen and (min-width: @screen-lg) {
    font-size: 20px;
  }
}

.no-user-screen {
  position: relative;

  & .message-icon {
    font-size: 3rem;
    color: @grey-500;
    margin-bottom: 20px;

    @media screen and (min-width: @screen-lg) {
      font-size: 5rem;
    }
  }
}

.message-screen {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.message-screen-scrollbar {
  position: relative;
  display: flex;
  flex-direction: column;
  // height: calc(100% - 132px);
  flex: 1;
  overflow: hidden;
  &::-webkit-scrollbar {
    display: none !important;
  }
}

.scroll-chat-no-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100%;
}

.noMessage {
  font-size: 18px;
  color: @grey-500;
}

.message-apps-footer {
  margin-top: auto;
}

.add-new-message-container {
  display: flex;
  flex-direction: column;
}

.add-new-message {
  display: flex;
}

.add-new-message-action {
  margin-left: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;

  [dir='rtl'] & {
    margin-left: 0;
    margin-right: 8px;
  }

  & .message-btn {
    color: @text-color-disabled;
    height: 40px;
    width: 40px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    border: none;

    &:disabled {
      background: none !important;
      opacity: 0.5;
    }

    & .anticon-send {
      margin-left: 3px;

      [dir='rtl'] & {
        margin-left: 0;
        margin-right: 3px;
      }
    }

    & + .message-btn {
      margin-left: 10px;

      [dir='rtl'] & {
        margin-left: 0;
        margin-right: 10px;
      }
    }
  }
}

.add-new-message-action-first {
  margin-left: 0;
  margin-right: 8px;

  [dir='rtl'] & {
    margin-right: 0;
    margin-left: 8px;
  }
}

.chat-header {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 0;

  & .user-info {
    width: 100%;
  }
}

.chat-header-action {
  margin-left: auto;
  display: flex;
  align-items: center;

  [dir='rtl'] & {
    margin-left: 0;
    margin-right: auto;
  }

  @media screen and (max-width: @screen-sm) {
    .btn-schedule {
      display: none;
    }
  }

  & .icon-btn {
    color: @text-color-secondary;
    background-color: fade(@primary-color, 5);
    margin-left: 5px;
    margin-right: 5px;

    &:hover,
    &:focus {
      background-color: fade(@black, 5);
      color: @text-color;
    }

    &.active {
      color: @primary-color;

      &:hover,
      &:focus {
        background-color: fade(@black, 5);
        color: @primary-color;
      }
    }
  }
}
