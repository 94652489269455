@import '../../../../shared/styles/variable';

.setup-profile-reminder {
  margin-top: 32px;
  background: #0a66c2;
  padding: 16px;
  padding-bottom: 0;

  .btn-close {
    color: #e2edf8;
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .btn-do-not-remind {
    margin-left: 12px;
  }

  @media screen and (max-width: @screen-xs) {
    justify-content: center;
    text-align: center;
    margin-top: 16px;

    .btn-do-not-remind {
      margin-left: 0px;
    }

    .btn-close {
      top: 8px;
      right: 0px;
    }
  }

  .ant-card-body {
    padding-bottom: 0;
  }

  &__content {
    .ant-typography,
    a,
    .ant-btn-link span {
      color: #b6d1ed;
    }

    .ant-btn-link span {
      &:hover {
        color: #dbeafb;
      }
    }

    .btn-complete {
      color: @secondary-color !important;
      margin: 24px 0;
      margin-bottom: 16px;
      &:hover {
        border-color: #015599;
      }
    }
  }

  &__title {
    color: white !important;
    margin-bottom: 16px;
    font-weight: 500 !important;
    font-size: 24px !important;
  }
}
