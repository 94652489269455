.loader-c {
  position: relative;
  margin: 0 auto;
  height: 90vh;

  display: -webkit-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;

  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  align-items: center;
  background: none;
}
