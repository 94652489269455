@import '../../../shared/styles/variable';

.group-detail-page {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  .group-banner-container {
    height: 320px;
    width: 100%;
    position: relative;
    justify-content: center;
    display: flex;
    align-items: flex-end;

    &__slide {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }

    &__gradient {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 320px;
      background: linear-gradient(
        rgba(0, 0, 0, 0.1) 0%,
        rgba(0, 0, 0, 0.9) 90%
      );
    }

    &__industry {
      background: #e2edf8;
      border-radius: 16px;
      padding: 6px 12px;
    }

    &__name {
      font-size: 32px;
      margin-top: 16px;
      color: white;
      line-height: 36px;
      text-align: left;
    }

    &__link {
      color: white;
      text-align: left;
      text-decoration: underline;
      &:hover {
        color: @secondary-color;
      }
    }

    .group-banner {
      width: 100%;
      height: 320px;
      background-size: cover;
      object-fit: cover;
    }

    .slick-dots li button::before {
      color: transparent !important;
    }

    .slick-dots {
      color: transparent !important;
      margin-bottom: -32px;
      margin-left: 0;
      margin-right: 0;
      button {
        background: #969696 !important;
      }
      .slick-active {
        button {
          background: #c00000 !important;
        }
      }
    }
  }

  @media screen and (max-width: @screen-xs) {
    .chat-content-screen {
      border-radius: 8px;
      border-bottom-width: 1px;
      border-top-width: 1px;
    }
  }

  @media screen and (max-width: @screen-xl) {
    .group-detail {
      padding: 0 16px;
    }
  }

  @media screen and (min-width: 1200px) {
    display: flex;
  }

  &__content {
    max-width: 1080px;
    flex: 1;
    width: 100%;
  }
}

.group-detail {
  .chat-content {
    height: calc(100vh - 140px);

    @media screen and (max-width: @screen-xs) {
      height: calc(100vh - 180px);
    }
  }

  &__desc {
    background: #fcfcfc;
    border-radius: 16px;
    font-size: 14px;
    line-height: 22px;
    padding: 16px;
    white-space: pre-line;
  }

  &__board {
    background: #fff;
    border: #f2f2f2 solid 1px;
    border-radius: 16px;
    font-size: 14px;
    line-height: 22px;
    padding: 16px;
    white-space: pre-line;
  }
}

.connection-modal {
  .connection-list {
    height: 300px;
    overflow-y: auto;

    .group-member-item {
      align-items: center;
      flex-direction: row;
      padding: 8px 8px;
      border-radius: 8px;

      .btn-cta {
        display: none;
      }
      &:hover {
        background: #f2f2f2;

        .btn-cta {
          display: unset;
        }
      }
    }
  }
}
