@import '../../../shared/styles/variable';

.voice-page {
  align-items: center;
  justify-content: center;
  padding: 0 16px;

  @media screen and (max-width: @screen-xs) {
    padding: 0;
  }

  @media screen and (min-width: 1200px) {
    display: flex;
  }

  &__content {
    max-width: 1080px;
    flex: 1;
  }
}

.btn-share {
  &:hover {
    transition-duration: 0.4s;
    color: #0a66c2 !important;
  }
}

.btn-control:hover {
  color: #c00000 !important;
  i,
  span {
    color: #c00000 !important;
  }
}

.voice-control {
  .ant-btn[disabled] {
    cursor: unset !important;
    &:hover {
      color: #808080 !important;
      i,
      span {
        color: #808080 !important;
      }
    }
  }

  .btn-control {
    &:hover {
      color: @secondary-color !important;
      i,
      span {
        color: @secondary-color !important;
      }
    }

    @media screen and (max-width: @screen-xs) {
      &:hover {
        color: #808080 !important;
        i,
        span {
          color: #808080 !important;
        }
      }
    }

    &:focus {
      color: #808080 !important;
      i,
      span {
        color: #808080 !important;
      }
    }
  }

  .btn-favor {
    i,
    span {
      color: @secondary-color !important;
    }

    &:active,
    &:hover,
    &:focus {
      color: @secondary-color !important;
      i,
      span {
        color: @secondary-color !important;
      }
    }
  }

  @media screen and (max-width: @screen-xs) {
    display: grid;
    grid-template-columns: auto auto;
    width: 100%;
    justify-content: center;
    text-align: center;
  }
}

.voice-item-card {
  margin-top: 24px;
  border: 1px solid #e6e6e6;
  border-radius: 16px;
  padding: 0;
  width: 100%;

  .ant-card-body {
    padding: 0 !important;
  }

  &__title {
    transition: all 0.3s ease-in-out;
    &:hover {
      color: #c00000 !important;
    }
  }

  &__body {
    padding: 24px;
    padding-top: 12px;
  }

  &__content {
    border: 1px dashed #c00000;
    border-radius: 8px;
    padding: 16px;
    margin-top: 8px;
    width: 100%;
    &__name {
      color: #333333;
      font-weight: 500;
      font-size: 14px;
    }
    &__answer-from {
      color: #808080;
    }
    &__header {
      align-items: center;
      display: flex;
      span {
        font-size: 14px;
        line-height: 14px;
      }
    }
  }

  &__message {
    color: #808080;
    margin-top: 16px;
    margin-bottom: 0 !important;
    padding-left: 0 !important;
    button {
      height: 20px;
      margin-left: -8px;
    }

    .btn-control,
    .ant-btn-link {
      color: #808080;
    }
  }

  &__footer {
    border: solid 1px #fff;
    border-top-color: #e6e6e6;
    justify-content: flex-start;
    span,
    i {
      color: #808080;
    }
  }
}

.voice-list {
  padding-bottom: 32px;
  .infinite-scroll-component__outerdiv {
    width: 100%;
  }
}

.voice-rep {
  width: 100%;
  &__fake-border {
    height: calc(100% - 30px);
    width: 8px;
    float: right;
    border-bottom-left-radius: 16px;
    border: solid 1px #e6e6e6;
    margin-left: 8px;
  }

  &__right-box {
    width: calc(100% - 16px);
  }

  .btn-view-more {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 8px;
    width: 100%;
    display: block;
    .ant-btn {
      font-size: 16px;
    }
  }

  .voice-item-card__comment-body {
    border-bottom: 1px solid #e6e6e6;
    padding: 16px;
    width: 100%;
  }
  .no-border {
    border-bottom: none;
  }
}

.Demo__some-network {
  vertical-align: top;
  display: inline-block;
  margin-right: 10px;
  text-align: center;
}

.Demo__some-network__share-count {
  display: inline-flex;
  justify-content: center;
  white-space: nowrap;
  overflow: visible;
  width: 0;
  margin-top: 3px;
  font-size: 12px;
}

.Demo__some-network__share-button {
  cursor: pointer;
}

.Demo__some-network__share-button:hover:not(:active) {
  opacity: 0.75;
}

.Demo__some-network__custom-icon {
  width: 32px;
  height: 32px;
}
