@import '../../../../shared/styles/variable';

.city-info-slide-card {
  position: relative;
  padding-bottom: 0;
  height: 52.2vw;
  box-shadow: none;

  @media screen and (max-width: @screen-sm) {
    height: 56vw;
  }

  & .slick-list,
  & .slick-track,
  & .slick-slide > div {
    height: 52.2vw;

    @media screen and (max-width: @screen-sm) {
      height: 54vw;
    }
  }

  & .slick-dots {
    top: 43vw;
  }

  & .slick-dots li {
    margin-left: 6px;
    margin-right: 6px;
    width: auto;
    height: auto;
  }

  & .slick-dots li button {
    width: 10px;
    height: 10px;

    @media screen and (min-width: @screen-xxl) {
      width: 14px;
      height: 14px;
    }
  }

  & .slick-dots li button:before {
    color: transparent;
    border: @border-style-base 2px white;
    opacity: 1;
    border-radius: @border-radius-circle;
    width: 10px;
    height: 10px;
    background: #66666644;

    @media screen and (min-width: @screen-xxl) {
      width: 14px;
      height: 14px;
    }
  }

  & .slick-dots li.slick-active button:before {
    background-color: @primary-color;
    border-color: @primary-color;
    color: @primary-color;
    font-size: 0;
  }

  .slick-arrow {
    .wc-icon {
      background-color: #ecb3b3;
      border-radius: 8px;
      padding: 16px 12px;
      color: #000000;
      font-size: 18px;
      font-weight: bold;
      opacity: 0.4;
      &:hover,
      &:focus {
        opacity: 0.7;
      }
    }
  }

  & .slick-prev {
    top: 351px;
    left: 80px;
    z-index: 1;

    @media screen and (max-width: @screen-sm) {
      top: 151px;
    }

    [dir='rtl'] & {
      left: auto;
      right: 36px;
    }

    &::before {
      content: '';
    }
  }

  & .slick-next {
    top: 351px;
    right: 80px;
    z-index: 1;

    @media screen and (max-width: @screen-sm) {
      top: 151px;
    }

    [dir='rtl'] & {
      right: auto;
      left: 36px;
    }

    &::before {
      content: '';
    }
  }
}

.city-info-slide {
  position: relative;
  text-align: center;
  height: 100%;
}

.city-info-slide-img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100% !important;
  height: 100% !important;
  object-fit: fill !important;

  @media screen and (max-width: 768px) {
    // object-fit: cover !important;
    // object-position: bottom right;
  }

  [dir='rtl'] & {
    left: auto;
    right: 0;
  }
}

.city-info-slide-img-content {
  width: 100%;
  height: 100%;
  color: @text-color-white;
  padding: 20px 20px 60px;
  display: flex;
  flex-direction: column;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: block;
    // background-color: fade(@tooltip-bg, 45);
    border-radius: inherit;
  }

  & > * {
    position: relative;
    z-index: 3;
  }

  & h3 {
    margin-bottom: 16px;
    font-size: @font-size-lg;
    font-weight: @font-weight-bold;
    color: @text-color-white;
  }
}

.city-info-slide-img-content-action {
  margin-top: auto;

  & p {
    margin-bottom: 0;
  }
}

.slider-card {
  margin: 24px 0 48px 0;
  .slick-list {
    padding-bottom: 16px;
  }
  .ant-card {
    margin: 0 8px;
  }
  .slick-dots {
    display: flex;
    justify-content: center;

    list-style-type: none;

    li {
      margin: 0 0.25rem;
    }

    button {
      display: block;
      width: 1rem;
      height: 0.25rem;
      padding: 0;

      border: none;
      border-radius: 2px;
      background-color: #e6e6e6;

      text-indent: -9999px;
    }

    li.slick-active button {
      background-color: #c00000;
    }
  }
}

.sub-info {
  display: flex;
  margin: 16px 24px;
  align-items: center;
  justify-content: space-between;
  .time-info {
    display: inline-flex;
    align-items: center;
    .dot {
      width: 4px;
      height: 4px;
      background-color: #808080;
      margin: 0 8px;
    }
  }
  .wc-icon {
    font-size: 24px;
    &:hover {
      cursor: pointer;
    }
  }
  .icon-ic_heart-filled {
    color: #c00000;
  }
}

.home-footer {
  position: relative;
  .ant-btn {
    padding: 8px 0 54px 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .footer-tab {
    min-height: 400px;
  }
}

.title-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
