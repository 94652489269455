@import '../../../shared/styles/variable';
.ant-tabs-tab-btn {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

.active-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .ant-radio-button-wrapper {
    height: 40px;
    display: inline-flex;
    align-items: center;
    line-height: 25px;
  }
}

.ant-picker {
  width: 100%;
}

.ant-rate {
  .ant-rate-star-full {
    color: #c00000;
  }
}

.search-item {
  display: flex;
  padding: 8px 0;
  align-items: center;
  .user-remove {
    text-align: end;
    & i {
      padding: 10px;
      &:hover {
        cursor: pointer;
        color: #c00003;
        font-weight: 900;
      }
    }
  }
}
.user-info {
  display: flex;
  flex: 1;
  text-align: left;
  flex-direction: column;
  justify-items: center;
  letter-spacing: 0.0168em;
  font-feature-settings: 'tnum' on, 'lnum' on;
  .user-name {
    text-align: left;
    width: 100%;
    height: 24px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
  }
  .user-designation {
    width: 100%;
    height: 18px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #808080;
  }
  .ant-typography {
    padding: 0 0 2px 12px;
  }
}

.record {
  &.active {
    background: #f7e0e0;
  }
  margin: 16px 0;
  .ant-card-body {
    font-family: 'Roboto';
    font-style: normal;
    letter-spacing: 0.0168em;
    border-radius: 14px !important;
    font-feature-settings: 'tnum' on, 'lnum' on;
    .ant-col {
      margin-bottom: 0;
    }
    .user {
      display: flex;
      flex-direction: column;
      .session {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: #333333;
        &.session-name {
          margin-bottom: 12px;
        }
      }
      .name {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #333333;
      }
      .by,
      .date {
        color: #808080;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
      .date {
        margin: 12px 0;
      }
      .info {
        display: flex;
        .ant-avatar {
          margin: 0 8px;
        }
      }
    }
    .cancel-btn {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .feedback {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      .ant-rate {
        .ant-rate-star-full {
          color: #c00000;
        }
      }
      .ant-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        width: 100%;
        max-width: 207px;
      }
      .displayNone {
        display: none;
      }
    }
    @media screen and (max-width: @screen-md) {
      .ant-col {
        display: flex;
        justify-content: center;
        align-items: center;
        .info {
          flex-direction: column;
          align-items: center;
        }
      }
      .feedback {
        margin-top: 16px;
      }
      .ant-avatar {
        margin: 12px !important;
      }
    }
  }
}

.modal-feedback {
  .ant-modal-body {
    text-align: center;
    padding-top: 0;
  }
  .desc {
    font-size: 16px;
    padding: 32px 0;
  }
  .ant-modal-footer {
    text-align: center;
    margin-bottom: 12px;
  }
}

.calendar {
  .calendar__header {
    margin-top: 24px;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .calendar__body {
    .ant-picker-calendar-header {
      display: none;
    }
  }
}
.ant-picker-content {
  thead {
    tr {
      text-align: center;
      th {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #808080;
      }
    }
  }
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #f0f0f0;
}

.ant-picker-cell-inner.ant-picker-calendar-date {
  margin: 0 !important;
  padding: 0 !important;
  .ant-picker-calendar-date-value {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
  }
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
  background-color: #c0000044 !important;
  .ant-picker-calendar-date-value {
    color: #f0f0f0;
  }
}
.note-schedule {
  display: block;
  height: 8px;
  width: 8px;
  background-color: #c00000;
  border-radius: 50%;
  position: absolute;
  right: 4px;
  top: 4px;
}

.p-0 {
  padding: 0;
}
.pl-4 {
  padding-left: 4px;
}
.pr-4 {
  padding-right: 4px;
}

.no-schedules {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;
  .ant-image {
    margin: 56px;
  }
  .ant-typography {
    font-weight: 400;
    font-size: 16px;
    color: #808080;
  }
}

.booked {
  text-align: left;
  padding-left: 8px;
  margin-top: 64px;
  font-size: 16px;
  line-height: 24px;
  color: #808080;
  span {
    margin-left: 15px;
  }
  .start-time {
    color: #333333;
    font-weight: 600;
    &.active {
      color: #c00000;
    }
  }
}
.ant-timeline-item-label {
  text-align: left !important;
  top: calc(50% - 108px) !important;
}
.ant-timeline-item-tail {
  height: 100% !important;
}
.ant-timeline-item-tail,
.ant-timeline-item-head {
  left: 80px !important;
  &.ant-timeline-item-head-blue {
    color: #d9d9d9 !important;
    border-color: #d9d9d9 !important;
    background-color: #d9d9d9 !important;
  }
}
.ant-timeline-item-content {
  width: calc(100% - 130px) !important;
  top: 0 !important;
  left: 100px !important;
}
.ant-timeline-item {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  &:last-child {
    display: none;
  }
}

.invitation {
  margin-bottom: 16px;
  .action {
    display: flex;
    justify-content: flex-end;
    .ant-btn {
      height: 48x;
      width: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:last-child {
        margin-left: 16px;
        background: #64AC07;
        border: none;
      }
    }
    @media screen and (max-width: @screen-xs) {
      justify-content: center;
    }
  }
  .wc-icon {
    font-size: 18px;
    &:hover {
      opacity: .5;
    }
  }
  .icon-ic_check {
    color: white;
  }
  .ant-typography {
    font-weight: 500;
    font-size: 20px;
    color: #333333;
    margin-bottom: 0px !important;
    font-size: 20px;
    line-height: 28px;
  }
  .avatar,
  .level {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .mentor {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .designation {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #808080;
    }
    @media screen and (max-width: @screen-xs) {
      flex-direction: column;
      align-items: center;
      gap: 16px;
      .name-level {
        display: flex;
        flex-direction: column;
      }
      .action {
        justify-content: center;
      }
    }
  }
  .name-level {
    display: flex;
    align-items: center;
  }
  .level {
    width: 119px;
    height: 22px;
    background: #F7E0E0;
    border-radius: 12px;
    color: #C00000;
    font-size: 14px;
    margin-left: 10px;
    line-height: 18px;
  }
  .schedule {
    background: #F2F2F2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px !important;
    .ant-card-body {
      padding: 0 !important;
      div {
        &:first-child {
          margin-bottom: 14px;
        }
      }
    }
  }
  .schedule__title {
    display: inline-block;
    min-width: 70px;
    color: #808080;
  }
  .schedule__content {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #0A66C2;
  }
}
