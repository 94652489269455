@import '../../../shared/styles/variable';

.main-content-view {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px 16px 0;
  min-height: auto;
  overflow: hidden;

  @media screen and (min-width: @screen-md) {
    padding: 16px 32px 0;
  }
}
