.mentor-page {
  h1 {
    color: #4d4d4d;
  }
}
.gift-card {
  width: 100%;
  .ant-card-body {
    display: flex;
    flex-direction: column;
    justify-content: right;
    align-items: center;
  }

  &__session {
    text-align: center;
    &__title {
      text-align: center;
      display: flex;
      width: 100%;
      justify-content: center;
      font-size: 14px;
    }

    &__value {
      text-align: center;
      justify-content: center;
      display: flex;
      width: 100%;
      font-size: 16px;
      font-weight: 500;
    }

    &__value-primary {
      color: #c00000;
    }
  }

  &__session-left {
    border: 0px solid #e6e6e6;
    border-right-width: 1px;
  }

  .ant-avatar {
    margin: 16px;
  }

  &__btn-message {
    font-size: 16px;
  }
  &__btn-more {
    position: absolute;
    top: 0px;
    right: 0px;
  }
}
