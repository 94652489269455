@import '../../../shared/styles/variable';

.event-detail-page {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  .event-banner-container {
    height: 320px;
    width: 100%;
    position: relative;
    justify-content: center;
    display: flex;
    align-items: flex-end;

    &__photos {
      height: 340px;
      position: relative;
      margin-top: 32px;
      justify-content: center;
      align-items: center;

      .slick-slide {
        text-align: center;
      }

      .event-photo {
        height: 340px;
        object-fit: cover;
      }

      .slick-dots {
        margin-left: unset;
        margin-right: unset;
      }

      .slick-dots li button::before {
        color: transparent !important;
      }

      .slick-dots {
        color: transparent !important;
        margin-bottom: -32px;
        margin-left: 0;
        margin-right: 0;
        button {
          background: #969696 !important;
        }
        .slick-active {
          button {
            background: #c00000 !important;
          }
        }
      }
    }

    &__slide {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }

    &__gradient {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 320px;
      background: linear-gradient(
        rgba(0, 0, 0, 0.1) 0%,
        rgba(0, 0, 0, 0.9) 90%
      );
    }

    &__industry {
      background: #e2edf8;
      border-radius: 16px;
      padding: 6px 12px;
    }

    &__name {
      font-size: 32px;
      margin-top: 16px;
      color: white;
      line-height: 36px;
      text-align: left;
    }

    &__link {
      color: white;
      text-align: left;
    }

    .event-banner {
      width: 100%;
      height: 320px;
      background-size: cover;
      object-fit: cover;
    }
  }

  @media screen and (max-width: @screen-xs) {
    .event-detail {
      padding: 0 16px;
    }
  }

  @media screen and (min-width: 1200px) {
    display: flex;
  }

  &__content {
    max-width: 1080px;
    flex: 1;
    width: 100%;
  }
}

.event-detail {
  &__desc {
    font-size: 16px;
    line-height: 22px;
    white-space: pre-line;
  }

  &__board {
    background: #fff;
    border: #f2f2f2 solid 1px;
    border-radius: 16px;
    font-size: 14px;
    line-height: 22px;
    padding: 16px;
    white-space: pre-line;
    display: flex;
    flex-direction: column;
  }
}

.event-modal {
  .event-list {
    height: 300px;
    overflow-y: auto;
  }
}

.event-invitation-modal {
  .event-invitation-list {
    .infinite-scroll-component__outerdiv {
      width: 100%;
    }
  }
}
