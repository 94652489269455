@import '../../../shared/styles/variable';

.notify-btn-all {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 100%;
  text-transform: capitalize;
}

.notify-link {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: @font-size-lg;
  border-radius: 0;
  padding: 24px 12px;
  margin-top: -10px;
  color: #4d4d4d;

  &:hover,
  &:focus {
    color: @text-color;
    background-color: transparent;
  }

  @media screen and (min-width: @screen-md) {
    font-weight: @font-weight-medium;
    text-transform: uppercase;
    margin-top: 0;
    width: 48px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    background-color: @body-background;
    color: @text-color-secondary;
    border-style: solid;
    border-color: #00000000;
    padding: 9px;
    margin-left: 7.4px;
    margin-right: 7.4px;
    line-height: 1;

    &:hover,
    &:focus {
      color: @text-color;
      background-color: lighten(@body-background, 0.5);
    }
  }

  .text-menu,
  .text-primary {
    word-break: normal;
  }
}

.dropdown-notification {
  @media screen and (max-width: @screen-xs) {
    padding: 0 4px !important;
  }
}

.notify-icon {
  position: relative;
  display: none;

  @media screen and (min-width: @screen-md) {
    display: block;
  }
}

.notify-text {
  font-size: @font-size-lg;
  font-weight: @font-weight-regular;

  @media screen and (min-width: @screen-md) {
    display: none;
  }
}

.notify-list {
  padding-top: 0;
  padding-bottom: 0;
}

.notify-scroll-submenu {
  height: 400px;

  @media screen and (min-width: @screen-xxl) {
    height: 580px;
  }
}

.notify-header-message {
  width: 360px;
  padding: 0;

  @media screen and (min-width: @screen-sm) {
    width: 400px;
  }

  @media screen and (min-width: @screen-xxl) {
    width: 480px;
  }

  & .ant-dropdown-menu-item {
    padding: 0;
    white-space: normal;

    &:hover {
      background-color: transparent;
    }

    &.header {
      padding: 12px 20px;
      font-weight: @font-weight-medium;
      cursor: inherit;
      text-align: center;
      border: 0px solid #e6e6e6;
      border-bottom-width: 1px;
      font-size: 20px;
      &:hover,
      &:focus {
        background-color: transparent;
      }
    }
  }
}
