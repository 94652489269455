@import '../shared/styles/variable';
@import '../shared/styles/mixin';

.badge-basic {
  border-radius: 0 !important;

  img {
    border: 4px solid white;
    border-radius: 200px;
    width: calc(100% - 8px) !important;
    height: calc(100% - 8px) !important;
    margin-left: 4px;
    margin-top: 4px;
  }

  &:before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    padding: 5px;
    border: 5px solid #c00000;
    border-radius: 200px;
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
    width: 72px;
    height: 72px;
    background: url('/assets/user/b_basic.svg') no-repeat;
  }
}

.badge-silver {
  border-radius: 0 !important;

  img {
    border: 4px solid white;
    border-radius: 200px;
    width: calc(100% - 8px) !important;
    height: calc(100% - 8px) !important;
    margin-left: 4px;
    margin-top: 4px;
  }

  &:before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    padding: 5px;
    border: 5px solid #808080;
    border-radius: 200px;
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
    width: 72px;
    height: 72px;
    background: url('/assets/user/b_silver.svg') no-repeat;
  }
}

.badge-gold {
  border-radius: 0 !important;

  img {
    border: 4px solid white;
    border-radius: 200px;
    width: calc(100% - 8px) !important;
    height: calc(100% - 8px) !important;
    margin-left: 4px;
    margin-top: 4px;
  }

  &:before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    padding: 5px;
    border: 5px solid #feaf18;
    border-radius: 200px;
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
    width: 72px;
    height: 72px;
    background: url('/assets/user/b_gold.svg') no-repeat;
  }
}
