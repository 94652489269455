@import '../../shared/styles/variable';
@import '../../shared/styles/mixin';

.overflow-3rows {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.user-modal-intro {
  padding: 16px;
  background: #f2f2f2;
  border-radius: 16px;
  margin-left: 16px;
  white-space: pre-line;
  max-height: 50vh;
  overflow-y: auto;
  margin-top: 16px;
  width: 100%;

  @media screen and (max-width: @screen-xs) {
    max-height: 30vh;
  }
}
