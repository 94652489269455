@import '../../../../shared/styles/variable';

.info-upload {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & .ant-form-item-control-input-content {
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

.info-upload-avatar {
  margin-right: 0px;
  width: 100%;
  margin-bottom: 10px;

  [dir='rtl'] & {
    margin-right: 0;
    margin-left: 16px;
  }

  @media screen and (min-width: @screen-lg) {
    width: 64px;
    height: 64px;
  }
}

.info-upload-content {
  flex: 1;
  font-size: @font-size-sm;
  color: @text-color-secondary;

  & p {
    margin-bottom: 0;
  }
}

.info-upload-btn-group {
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: center;

  & .dropzone {
    margin-right: 10px;
    margin-left: 10px;

    [dir='rtl'] & {
      margin-right: 0;
      margin-left: 10px;
    }
  }

  & .ant-btn {
    height: 30px;
    padding: 3.5px 12px;
  }
}

.mentor-card {
  background: #e2edf8;
  padding: 8px 0;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  text-align: left;
  h3 {
    text-transform: uppercase;
    color: #0a66c2;
    font-size: 16px;
    font-weight: 500;
  }

  button,
  button:hover {
    background: #0a66c2;
    border: #0a66c2;
  }
}

.setup-account-card {
  background: #fde2ca !important;
  h3 {
    color: #d35400 !important;
  }

  button,
  button:hover {
    background: #d35400 !important;
    border: #d35400 !important;
  }
}

.reward-card {
  padding: 0px 0;
  margin-top: 32px;
  text-align: left;
  background: url('/assets/user/bg_reward.png') bottom right / cover no-repeat;
  display: flex;
  flex-direction: row;

  h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
  }

  &__d-info {
    padding: 0;
    button {
      margin-top: -8px;
      margin-right: -16px;
      background: none;
      padding: 0;
      border: none;
      color: #808080;
    }
  }

  &__point-lg {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: 0.0168em;
    color: #4d4d4d;
    margin-top: 12px;
  }

  &__point-xs {
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    color: #c00000;
  }

  &__point-xs2 {
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.0168em;
    color: #0a66c2;
  }

  &__coin-xs {
    margin-bottom: -2px;
  }

  &__point-max {
    font-size: 12px;
    line-height: 14px;
    color: #4d4d4d;
    margin-top: 4px;
  }

  &__text {
    color: #808080;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }

  &__progress {
    margin-right: 8px;
    flex: 1;
    .ant-progress-success-bg,
    .ant-progress-bg {
      background-color: #d34d4d;
    }
  }
}

.tb-membership {
  th,
  td {
    border: 1px solid #efefef;
    padding: 16px;
  }

  &__top {
    vertical-align: top;
  }

  tbody {
    overflow-y: auto;
    overflow-x: hidden;
  }

  width: 100%;
}
