.custom-tags {
  background: #f2f2f2;
  min-height: 48px;
  border-radius: 8px;
  padding: 8px 16px;
  align-items: center;

  .tag-input {
    background: #f2f2f2;
    border: none;
  }

  .empty-input {
    min-width: 220px;
    margin-left: -8px;
  }
}

.footer-fixed {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}
