@import '../../../shared/styles/variable';

.community-page {
  align-items: center;
  justify-content: center;
  padding: 0 16px;

  .request-badge .ant-badge-count {
    right: -16px;
    top: 8px;
  }

  @media screen and (max-width: @screen-xs) {
    padding: 0;

    .filter-container {
      flex-direction: column;
      align-items: flex-start;
    }

    .request-badge .ant-badge-count {
      right: 10px;
      top: -4px;
    }
  }

  @media screen and (min-width: 1200px) {
    display: flex;
  }

  &__content {
    max-width: 1080px;
    flex: 1;
  }
}

.mentor-page {
  h1 {
    color: #4d4d4d;
  }
}

.community-item {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 152px;
  width: 100%;
  margin-bottom: 16px;

  .ant-card-body {
    width: 100%;
  }

  &__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: auto;
    margin: 0;
    padding: 0;
  }

  &__img {
    width: 100%;
    height: 120px;
    border-radius: 8px;
    overflow: hidden;
    background-size: cover;
  }

  &__middle {
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding-left: 24px !important;
    margin-bottom: 0 !important;

    .community-title {
      width: 100%;
    }

    .community-desc {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  &__end {
    justify-content: flex-end;
    display: flex;
    align-items: center;
    margin-bottom: 0 !important;
  }

  @media screen and (max-width: 768px) {
    height: auto;

    &__row {
      padding-top: 0;
    }

    &__middle {
      margin-top: 8px;
    }
  }
}

.connection-request {
  &__btn-group {
    justify-content: flex-end;
    display: flex;
  }

  @media screen and (max-width: 768px) {
    &__btn-group {
      margin-top: 24px !important;
    }
  }

  &__btn-close {
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    padding: 0;
    display: flex;
    color: #c00000;
    border: 1px solid #c00000;

    i {
      font-size: 24px;
    }
  }

  &__btn-accept {
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    padding: 0;
    display: flex;
    color: white;
    background: #64ac07;
    border: 1px solid #64ac07;

    i {
      font-size: 24px;
    }

    &:hover,
    &:focus {
      background: #64ac07;
      border: 1px solid #64ac07;
    }
  }

  &__notes {
    background: #f2f2f2;
    border-radius: 16px;
    padding: 16px;
    width: 100%;
  }
}

.mentor-carousel {
  .slick-dots {
    width: calc(100% - 26%);
    margin-bottom: -40px;

    button {
      background: #969696 !important;
    }

    .slick-active {
      button {
        background: #c00000 !important;
      }
    }
  }
}

.group-invitation {
  background: #f2f2f2;
  border-radius: 16px;
  margin-top: 24px;
  padding: 16px 8px;

  .img {
    height: 60px;
    width: 100%;
    border-radius: 4px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .group-desc {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: 12px;
  }

  .group-name {
    color: '#333';
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  @media screen and (max-width: 768px) {
    .img {
      height: 140px;
      margin-bottom: 8px;
    }
  }
}

.event-card {
  display: flex;
  flex-direction: column;

  .ant-card-body {
    padding: 0;
  }

  .event-img {
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    height: 180px;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    background-color: #bab6b6;
    cursor: pointer;
  }

  .event-body {
    padding: 16px 16px 12px 12px;
    display: flex;
    flex-direction: column;
  }

  .event-title-link {
    height: 80px;
  }

  .event-title {
    width: 100%;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.0168em;
    font-feature-settings:
      'tnum' on,
      'lnum' on;
    color: #333333;

    text-overflow: ellipsis;
    overflow: hidden;

    @supports (-webkit-line-clamp: 3) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  .event-desc {
    margin: 8px 0 12px 0;
    width: 100%;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.0168em;
    font-feature-settings:
      'tnum' on,
      'lnum' on;
    color: #808080;
    white-space: nowrap;

    text-overflow: ellipsis;
    overflow: hidden;
    min-height: 50px;

    @supports (-webkit-line-clamp: 3) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }

  .event-location {
    min-height: 56px;

    @supports (-webkit-line-clamp: 3) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
}

.event-create-form {
  .info-upload-content {
    .btn-remove {
      position: absolute;
      height: 48px;
      left: calc(50% - 28px);
    }

    .feature-image-overlay {
      background: #000000;
      opacity: 0.2;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 8px;
    }
  }
}
