.group-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}

.group-name {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.0168em;
  font-feature-settings:
    'tnum' on,
    'lnum' on;
  color: #4d4d4d;
}

.btn-view-more {
  // height: 20px;
  padding: 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-feature-settings:
    'tnum' on,
    'lnum' on;
  color: #c00000;
}

.user-card {
  .ant-card-body {
    display: flex;
    flex-direction: column;
    justify-content: right;
    align-items: center;
  }

  &__session {
    text-align: center;

    &__title {
      text-align: center;
      display: flex;
      width: 100%;
      justify-content: center;
      font-size: 14px;
    }

    &__value {
      text-align: center;
      justify-content: center;
      display: flex;
      width: 100%;
      font-size: 16px;
      font-weight: 500;
    }

    &__value-primary {
      color: #c00000;
    }
  }

  &__session-left {
    border: 0px solid #e6e6e6;
    border-right-width: 1px;
  }

  .ant-avatar {
    margin: 16px;
  }

  &__btn-message {
    font-size: 16px;
  }

  &__btn-more {
    position: absolute;
    top: 0px;
    right: 0px;
  }
}

.user-avatar {
  background: url('../../../assets/user/user-default-avatar.png') top center /
    cover no-repeat;
}

.user-mentees {
  margin: 8px;
  background: #e2edf8;
  border-radius: 12px;
  border: none;
  color: #0a66c2;
  margin: 10px 0;
  padding: 2px 12px;
}

.user-mentor {
  margin: 8px;
  background: #f7e0e0;
  border-radius: 12px;
  border: none;
  color: #c00000;
  margin: 10px 0;
  padding: 2px 12px;
}

.tag-pending {
  margin: 8px;
  background: #ffedba !important;
  border-radius: 12px !important;
  border: none;
  color: #896702;
  margin: 10px 0;
  padding: 2px 12px;
}

.tag-info {
  margin: 8px;
  background: #e2edf8;
  border-radius: 12px;
  border: none;
  color: #0a66c2;
  margin: 10px 0;
  padding: 2px 12px;
}

.tag-danger {
  margin: 8px;
  background: #f7e0e0;
  border-radius: 12px;
  border: none;
  color: #c00000;
  margin: 10px 0;
  padding: 2px 12px;
}

.user-name {
  width: 223px;
  height: 28px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.0168em;
  font-feature-settings:
    'tnum' on,
    'lnum' on;
  color: #333333;
}

.user-workplace {
  margin: 8px 0 12px 0;
  width: 223px;
  height: 18px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.0168em;
  font-feature-settings:
    'tnum' on,
    'lnum' on;

  color: #808080;
}

.community-card {
  display: flex;
  flex-direction: column;

  .ant-card-body {
    padding: 0;
  }
}

.community-img {
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  height: 180px;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  background-color: #bab6b6;
}

.community-hover-img {
  cursor: pointer;
}

.community-body {
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.community-title {
  height: 56px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.0168em;
  font-feature-settings:
    'tnum' on,
    'lnum' on;
  color: #333333;
  display: block;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.community-desc {
  margin: 8px 0 24px 0;
  width: 100%;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.0168em;
  font-feature-settings:
    'tnum' on,
    'lnum' on;
  color: #808080;
  white-space: nowrap;

  text-overflow: ellipsis;
  overflow: hidden;
  min-height: 50px;

  @supports (-webkit-line-clamp: 3) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
